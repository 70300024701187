import actions from './actions';
import { fromJS } from 'immutable';

const initialGroup = {
  name: '',
}

const initialUnit = {
  name: '',
  designation: '',
  description: ''
}

const initialCompany = {
  name: '',
  address: '',
  contacts: ''
}

const initialState = fromJS({
  searchText: '',
  
  groupsById: {},
  editableGroup: initialGroup,
  groupsError: {},
  groupUpdateProcessSuccessful: false,
  isGroupsFetching: false,
  isGroupCreating: false,
  isGroupUpdating: false,
  isGroupDeleting: false,

  unitsById: {},
  unitsError: {},
  unitUpdateProcessSuccessful: false,
  editableUnit: initialUnit,
  isUnitsFetching: false,
  isUnitCreating: false,
  isUnitUpdating: false,
  isUnitDeleting: false,

  companiesById: {},
  companiesError: {},
  companyUpdateProcessSuccessful: false,
  editableCompany: initialCompany,
  isCompaniesFetching: false,
  isCompanyCreating: false,
  isCompanyUpdating: false,
  isCompanyDeleting: false,

  typesById: {},
  editableType: initialGroup,

  parametersById: {},
});
export default function(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SEARCH_TEXT:
      return state.updateIn(
        ['searchText'],
        () => action.payload,
      );

    // case actions.:
    //   return { ...state, isGroupFetching: true };

    case actions.SET_GROUPS_DATA:
      return state
        .mergeDeep(action.payload)
        .updateIn(['groupsById'], groupsById =>
          fromJS(action.payload.groupsById ? action.payload.groupsById : groupsById),
        )
        .updateIn(['groupUpdateProcessSuccessful'], () =>
          fromJS(action.payload.groupUpdateProcessSuccessful ? action.payload.groupUpdateProcessSuccessful : false),
        )
        .updateIn(['groupsError'], () =>
          fromJS(action.payload.groupsError ? action.payload.groupsError : {}) );

    case actions.SET_EDITABLE_GROUP_DATA:
      return state
        .updateIn(['editableGroup'], () => action.payload)
        .updateIn(['groupUpdateProcessSuccessful'], () =>
          fromJS(action.payload.groupUpdateProcessSuccessful ? action.payload.groupUpdateProcessSuccessful : false),
        )
        .updateIn(['groupsError'], () =>
          fromJS(action.payload.groupsError ? action.payload.groupsError : {}) );

    case actions.CLEAR_GROUP_DATA:
      return state
        .updateIn(['editableGroup'], () => initialGroup)
        .updateIn(['groupUpdateProcessSuccessful'], () => false)
        .updateIn(['groupsError'], () => ({}) );





    case actions.SET_UNITS_DATA:
      return state
        .mergeDeep(action.payload)
        .updateIn(['unitsById'], unitsById =>
          fromJS(action.payload.unitsById ? action.payload.unitsById : unitsById),
        )
        .updateIn(['unitUpdateProcessSuccessful'], () =>
          fromJS(action.payload.unitUpdateProcessSuccessful ? action.payload.unitUpdateProcessSuccessful : false),
        )
        .updateIn(['unitsError'], () =>
          fromJS(action.payload.unitsError ? action.payload.unitsError : {}) );

    case actions.SET_EDITABLE_UNIT_DATA:
      return state
        .updateIn(['editableUnit'], () => action.payload)
        .updateIn(['unitUpdateProcessSuccessful'], () =>
          fromJS(action.payload.unitUpdateProcessSuccessful ? action.payload.unitUpdateProcessSuccessful : false),
        )
        .updateIn(['unitsError'], () =>
          fromJS(action.payload.unitsError ? action.payload.unitsError : {}) );

    case actions.CLEAR_UNIT_DATA:
      return state
        .updateIn(['editableUnit'],() => initialUnit)
        .updateIn(['unitUpdateProcessSuccessful'], () => false)
        .updateIn(['unitsError'], () => ({}) );



    case actions.SET_COMPANIES_DATA:
      return state
        .mergeDeep(action.payload)
        .updateIn(['companiesById'], companiesById =>
          fromJS(action.payload.companiesById ? action.payload.companiesById : companiesById),
        )
        .updateIn(['companyUpdateProcessSuccessful'], () =>
          fromJS(action.payload.companyUpdateProcessSuccessful ? action.payload.companyUpdateProcessSuccessful : false),
        )
        .updateIn(['companiesError'], () =>
          fromJS(action.payload.companiesError ? action.payload.companiesError : {}) );

    case actions.SET_EDITABLE_COMPANY_DATA:
      return state.updateIn(['editableCompany'], () => action.payload)
        .updateIn(['companyUpdateProcessSuccessful'], () =>
          fromJS(action.payload.companyUpdateProcessSuccessful ? action.payload.companyUpdateProcessSuccessful : false),
        )
        .updateIn(['companiesError'], () =>
          fromJS(action.payload.companiesError ? action.payload.companiesError : {}) );

    case actions.CLEAR_COMPANY_DATA:
      return state.updateIn(['editableCompany'], () => initialCompany)
        .updateIn(['companyUpdateProcessSuccessful'], () => false)
        .updateIn(['companiesError'], () => ({}) );



    case actions.SET_TYPES_DATA:
      return { ...state, typesById: action.payload };

    case actions.SET_TYPE_DATA:
      return { ...state, typesById: action.payload };

    case actions.CREATE_TYPE:
      return { ...state, typesById: action.payload };

    case actions.DELETE_TYPE:
      return { ...state, typesById: action.payload };

    case actions.UPDATE_TYPE:
      return { ...state, typesById: action.payload };




    case actions.SET_PARAMETERS_DATA:
      return { ...state, parametersById: action.payload };

    case actions.SET_PARAMETER_DATA:
      return { ...state, parametersById: action.payload };

    case actions.CREATE_PARAMETER:
      return { ...state, parametersById: action.payload };

    case actions.DELETE_PARAMETER:
      return { ...state, parametersById: action.payload };

    case actions.UPDATE_PARAMETER:
      return { ...state, parametersById: action.payload };





    default:
      return state;
  }
}
