import actions from './actions';
import { fromJS } from 'immutable';

const initialProject = {
  title: '',
  coordinators_ids: [],
  company_id: undefined,
  address: '',
  contacts: '',
};

const initialState = fromJS({
  projectsById: {},
  searchText: '',

  isProjectsFetching: false,
  isProjectFetching: false,
  isProjectCreating: false,
  isProjectUpdating: false,
  isProjectDeleting: false,

  currentProject: initialProject,
  editableProject: initialProject,
  enableEditView: false,

  auditGroupsById: {},
  searchGroupText: '',

  isAuditGroupsFetching: false,
  isAuditGroupFetching: false,
  isAuditGroupCreating: false,
  isAuditGroupUpdating: false,
  isAuditGroupDeleting: false,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PROJECTS_DATA:
      return state
        .mergeDeep(action.payload)
        .updateIn(['projectsById'], projectsById =>
          fromJS(action.payload.projectsById ? action.payload.projectsById : projectsById),
        );

    case actions.SET_CURRENT_PROJECT_DATA:
      return state
        .mergeDeep(action.payload)
        .updateIn(['currentProject'], project =>
          fromJS(action.payload.project ? action.payload.project : project),
        );

    case actions.SET_PROJECT_SEARCH_TEXT:
      return state
        .updateIn(['searchText'], () => action.payload);

    // case actions.DELETE_PROJECT:
    //   return state
    //     .mergeDeep(action.payload)
    //     .updateIn(['projectsById'], projectsById =>
    //       fromJS(action.payload.projectsById ? action.payload.projectsById : projectsById),
    //     );

    case actions.INITIAL_PROJECT_DATA:
      return state
        .mergeDeep(action.payload)
        .updateIn(['currentProject'], () =>
          fromJS(initialProject),
        )
        .updateIn(['editableProject'], () =>
          fromJS(initialProject),
        );

    case actions.CLEAR_PROJECT_DATA:
      return state
        .mergeDeep(action.payload)
        .updateIn(['currentProject'], () =>
          fromJS(initialProject),
        )
        .updateIn(['editableProject'], () =>
          fromJS(initialProject),
        )
        .updateIn(['enableEditView'], enableEditView =>
          fromJS(false),
        );

    case actions.TOGGLE_VIEW:
      return state
        .mergeDeep(action.payload)
        .updateIn(['enableEditView'], enableEditView =>
          fromJS(!enableEditView),
        );

    case actions.SET_AUDIT_GROUPS:
      return state
        .mergeDeep(action.payload)
        .updateIn(['auditGroupsById'], auditGroupsById =>
          fromJS(action.payload.auditGroupsById ? action.payload.auditGroupsById : auditGroupsById),
        );

    case actions.SET_AUDIT_GROUP_SEARCH_TEXT:
      return state
        .updateIn(['searchGroupText'], () => action.payload);

    default:
      return state;
  }
}
