import Enlang from './entries/en-US';
import Russian from './entries/ru-RU';
// import Zhlang from './entries/zh-Hans-CN';
// import Salang from './entries/ar_SA';
// import Itlang from './entries/it_IT';
// import Eslang from './entries/es_ES';
// import Frlang from './entries/fr_FR';

const AppLocale = {
  en: Enlang,
  ru: Russian,
  // zh: Zhlang,
  // sa: Salang,
  // it: Itlang,
  // es: Eslang,
  // fr: Frlang,
};

export default AppLocale;
