const actions = {
  //USER
  CREATE_USER_WATCHER: 'CREATE_USER_WATCHER',
  CREATE_USER: 'CREATE_USER',

  DELETE_USER_WATCHER: 'DELETE_USER_WATCHER',
  DELETE_USER: 'DELETE_USER',

  UPDATE_USER_WATCHER: 'UPDATE_USER_WATCHER',
  UPDATE_USER: 'UPDATE_USER',

  SELECT_CURRENT_USER: 'SELECT_CURRENT_USER',

  UPDATE_EDITABLE_USER: 'UPDATE_EDITABLE_USER',
  UPDATE_NEW_USER: 'UPDATE_NEW_USER',

  // SEARCH
  SET_USER_SEARCH_TEXT: 'SET_USER_SEARCH_TEXT',
  SET_SORT_ORDER: 'SET_SORT_ORDER',

  // LOAD DATA
  LOAD_USERS_DATA_SAGA: 'LOAD_USERS_DATA_SAGA',
  LOAD_CURRENT_USER_DATA_SAGA: 'LOAD_CURRENT_USER_DATA_SAGA',
  SET_USERS_DATA: 'SET_USERS_DATA',
  SET_CURRENT_USER_DATA: 'SET_CURRENT_USER_DATA',

  CLEAR_USER_DATA: 'CLEAR_USER_DATA',
  SET_EDITABLE_USER_DATA: 'SET_EDITABLE_USER_DATA',

  //PASSWORD
  GET_PASSWORD_WATCHER: 'GET_PASSWORD_WATCHER',
  GET_PASSWORD: 'GET_PASSWORD',

  UPDATE_PASSWORD_WATCHER: 'UPDATE_PASSWORD_WATCHER',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',

  // Load Data Actions
  usersRenderWatcher: (payload = {}) => ({
    type: actions.LOAD_USERS_DATA_SAGA,
    payload
  }),
  setUsersData: users => {
    return {
      type: actions.SET_USERS_DATA,
      payload: users,
    }
  },

  setEditableUser: user => ({type: actions.SET_EDITABLE_USER_DATA, payload: user}),
  clearUserData: () => ({type: actions.CLEAR_USER_DATA}),

  userRenderWatcher: userId => ({
    type: actions.LOAD_CURRENT_USER_DATA_SAGA,
    payload: userId,
  }),
  setUserData: userData => ({
    type: actions.SET_CURRENT_USER_DATA,
    payload: userData,
  }),

  setUsersSearchText: payload => ({
    type: actions.SET_USER_SEARCH_TEXT,
    payload,
  }),

  setSortOrder: payload => ({
    type: actions.SET_SORT_ORDER,
    payload,
  }),

  // User Actions
  createUserWatcher: user => {
    return {
      type: actions.CREATE_USER_WATCHER,
      payload: user,
    };
  },

  deleteUserWatcher: userID => {
    return {
      type: actions.DELETE_USER_WATCHER,
      payload: userID,
    };
  },

  updateUserWatcher: (user, id) => {
    return {
      type: actions.UPDATE_USER_WATCHER,
      payload: {user, id},
    };
  },

  //Password actions
  getPasswordWatcher: userID => {
    return {
      type: actions.GET_PASSWORD_WATCHER,
      payload: userID,
    };
  },
  getPassword: password => {
    return {
      type: actions.GET_PASSWORD,
      payload: password,
    };
  },

  updatePasswordWatcher: (userId, password) => {
    return {
      type: actions.UPDATE_PASSWORD_WATCHER,
      payload: {userId, password},
    };
  },
  updatePassword: (password) => {
    return {
      type: actions.UPDATE_PASSWORD,
      payload: password,
    };
  },

  // PERMISSIONS
  LOAD_PERMISSIONS_SAGA: 'LOAD_PERMISSIONS_SAGA',
  SET_PERMISSIONS_DATA: 'SET_PERMISSIONS_DATA',

  permissionsRenderWatcher: () => ({ type: actions.LOAD_PERMISSIONS_SAGA }),
  setPermissionsData: payload => ({
    type: actions.SET_PERMISSIONS_DATA,
    payload,
  }),

  //ROLE
  LOAD_ROLES_SAGA: 'LOAD_ROLES_SAGA',
  SET_ROLES_DATA: 'SET_ROLES_DATA',
  LOAD_CURRENT_ROLE_DATA_SAGA: 'LOAD_CURRENT_ROLE_DATA_SAGA',
  SET_CURRENT_ROLE_DATA: 'SET_CURRENT_ROLE_DATA',

  CREATE_ROLE_WATCHER: 'CREATE_ROLE_WATCHER',
  CREATE_ROLE: 'CREATE_ROLE',

  DELETE_ROLE_WATCHER: 'DELETE_ROLE_WATCHER',
  DELETE_ROLE: 'DELETE_ROLE',

  UPDATE_ROLE_WATCHER: 'UPDATE_ROLE_WATCHER',
  UPDATE_ROLE: 'UPDATE_ROLE',

  SELECT_CURRENT_ROLE: 'SELECT_CURRENT_ROLE',
  CLEAR_ROLE_DATA: 'CLEAR_ROLE_DATA',
  SET_EDITABLE_ROLE_DATA: 'SET_EDITABLE_ROLE_DATA',
  TOGGLE_ROLE_VIEW: 'TOGGLE_ROLE_VIEW',

  //Role data action
  rolesRenderWatcher: () => ({ type: actions.LOAD_ROLES_SAGA }),
  setRolesData: payload => ({
    type: actions.SET_ROLES_DATA,
    payload,
  }),

  roleRenderWatcher: roleId => ({
    type: actions.LOAD_CURRENT_ROLE_DATA_SAGA,
    payload: roleId,
  }),
  setRoleData: roleData => ({
    type: actions.SET_CURRENT_ROLE_DATA,
    payload: roleData,
  }),

  // Role Actions
  createRoleWatcher: role => {
    return {
      type: actions.CREATE_ROLE_WATCHER,
      payload: role,
    };
  },

  deleteRoleWatcher: (roleId, newUsersRoleId) => {
    return {
      type: actions.DELETE_ROLE_WATCHER,
      payload: {roleId, newUsersRoleId},
    };
  },

  updateRoleWatcher: (role, id) => {
    return {
      type: actions.UPDATE_ROLE_WATCHER,
      payload: {role, id},
    };
  },

  toggleRoleView: view => ({ type: actions.TOGGLE_ROLE_VIEW, view }),
  setSelectedRole: role => ({type: actions.SELECT_CURRENT_ROLE, payload: {role}}),
  setEditableRole: role => ({type: actions.SET_EDITABLE_ROLE_DATA, payload: {role}}),
  clearRoleData: role => ({type: actions.CLEAR_ROLE_DATA, payload: {role}}),

};

export default actions;
