const projectsActions = {
  //PROJECT
  LOAD_CURRENT_PROJECT_DATA_SAGA: 'LOAD_CURRENT_PROJECT_DATA_SAGA',
  SET_CURRENT_PROJECT_DATA: 'SET_CURRENT_PROJECT_DATA',

  CREATE_PROJECT_WATCHER: 'CREATE_PROJECT_WATCHER',
  DELETE_PROJECT_WATCHER: 'DELETE_PROJECT_WATCHER',
  UPDATE_PROJECT_WATCHER: 'UPDATE_PROJECT_WATCHER',

  UPDATE_EDIT_PROJECT: 'UPDATE_EDIT_PROJECT',
  SELECT_CURRENT_PROJECT: 'SELECT_CURRENT_PROJECT',

  TOGGLE_VIEW: 'PROJECT_TOGGLE_VIEW',

  CLEAR_PROJECT_DATA: 'CLEAR_PROJECT_DATA',
  INITIAL_PROJECT_DATA: 'INITIAL_PROJECT_DATA',


  // SEARCH
  SET_PROJECT_SEARCH_TEXT: 'SET_PROJECT_SEARCH_TEXT',

  // LOAD DATA
  LOAD_PROJECTS_DATA_SAGA: 'LOAD_PROJECTS_DATA_SAGA',
  SET_PROJECTS_DATA: 'SET_PROJECTS_DATA',

  // Load Data Actions

  projectsRenderWatcher: (payload = {}) => ({
    type: projectsActions.LOAD_PROJECTS_DATA_SAGA,
    payload
  }),

  setProjectsData: payload => ({
    type: projectsActions.SET_PROJECTS_DATA,
    payload,
  }),

  setProjectSearchText: payload => {
    return {
      type: projectsActions.SET_PROJECT_SEARCH_TEXT,
      payload,
    };
  },


  // Project Actions
  clearProjectData: () => ({type: projectsActions.CLEAR_PROJECT_DATA}),
  initialProjectData: () => ({type: projectsActions.INITIAL_PROJECT_DATA}),

  projectRenderWatcher: projectId => ({
    type: projectsActions.LOAD_CURRENT_PROJECT_DATA_SAGA,
    payload: {id: projectId},
  }),
  setProjectData: payload => ({
    type: projectsActions.SET_CURRENT_PROJECT_DATA,
    payload,
  }),

  createProjectWatcher: project => {
    return {
      type: projectsActions.CREATE_PROJECT_WATCHER,
      payload: project,
    };
  },

  deleteProjectWatcher: projectId => {
    return {
      type: projectsActions.DELETE_PROJECT_WATCHER,
      payload: projectId,
    };
  },

  updateProjectWatcher: project => {
    return {
      type: projectsActions.UPDATE_PROJECT_WATCHER,
      payload: project,
    };
  },

  toggleView: view => ({ type: projectsActions.TOGGLE_VIEW, view }),
  editProject: project => ({ type: projectsActions.UPDATE_EDIT_PROJECT, project }),



  LOAD_AUDIT_GROUPS_SAGA: 'LOAD_AUDIT_GROUPS_SAGA',
  SET_AUDIT_GROUPS: 'SET_AUDIT_GROUPS',

  ADD_AUDIT_GROUP_WATCHER: 'ADD_AUDIT_GROUP_WATCHER',
  REMOVE_AUDIT_GROUP_WATCHER: 'REMOVE_AUDIT_GROUP_WATCHER',

  // SEARCH
  SET_AUDIT_GROUP_SEARCH_TEXT: 'SET_AUDIT_GROUP_SEARCH_TEXT',


  auditGroupsRenderWatcher: (params = {}) => ({
    type: projectsActions.LOAD_AUDIT_GROUPS_SAGA,
    payload: params
  }),

  setAuditGroupsData: payload => ({
    type: projectsActions.SET_AUDIT_GROUPS,
    payload,
  }),

  setAuditGroupsSearchText: payload => {
    return {
      type: projectsActions.SET_AUDIT_GROUP_SEARCH_TEXT,
      payload,
    };
  },

  addAuditGroupWatcher: (group, projectId) => {
    return {
      type: projectsActions.ADD_AUDIT_GROUP_WATCHER,
      payload: {group, projectId},
    };
  },

  removeAuditGroupWatcher: groupId => {
    return {
      type: projectsActions.REMOVE_AUDIT_GROUP_WATCHER,
      payload: groupId,
    };
  },

};

export default projectsActions;
