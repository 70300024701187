import { all, takeEvery, put, select, call } from 'redux-saga/effects';
import api from '@axicom/api';
import actions from './actions';
import DemoData from './data';
import { setRequestParams } from '@axicom/lib/helpers/utility';
//import { loadState, saveState } from '@iso/lib/helpers/localStorage';

const getCatalog = state => state.Catalog;

const getObject = (data, property = 'id') => {
  return data.reduce((obj, cur) => {
    obj[cur[property]] = {
      ...cur,
      key: cur.id
    };
    return obj;
  }, {});
};

//
// function* groupsRenderEffectSaga() {
//   yield takeEvery('FETCHED_DOG', fetchDogAsync);
// }

function* groupsRenderEffectSaga({ payload }) {
  try {
    //yield put(requestDog());
    yield put(actions.setGroupsData({ isGroupsFetching: true }));
    const data = yield call(() => api.get(`audit_groups/${setRequestParams(payload.params)}`));
    yield put(actions.setGroupsData({ groupsById: getObject(data) }));
    yield put(actions.setGroupsData({ isGroupsFetching: false }));
    //yield put(actions.clearGroupData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Group loading error');
    yield put(actions.setGroupsData({ isGroupsFetching: false, groupsError: error }));
  }
}

function* groupRenderEffectSaga({ payload }) {
  try {
    //yield put(requestDog());
    let stateGroups = yield select(getCatalog);
    yield put(actions.setGroupsData({ isGroupFetching: true }));
    const data = yield call(() => api.get('audit_groups'));
    yield put(actions.setGroupsData({ groupsById: data }));
    yield put(actions.setGroupsData({ isGroupFetching: false }));
    //yield put(actions.clearGroupData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Group loading error');
    yield put(actions.setGroupsData({ pending: false, groupsError: error }));
  }
}

export function* createGroupEffectSaga(action) {
  try {
    //yield put(requestDog());
    const groupsById = yield select(state =>
      state.Catalog.getIn(['groupsById']).toJS(),
    );
    yield put(actions.setGroupsData({ isGroupCreating: true }));
    const data = yield call(() => api.post('audit_groups', action.payload));
    yield put(actions.setGroupsData({ groupsById: {...groupsById, [data.id]: {...data, key: data.id}} }));
    yield put(actions.setGroupsData({ isGroupCreating: false, groupUpdateProcessSuccessful: true }));
    yield put(actions.clearGroupData());
    return data;
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Group loading error');
    yield put(actions.setGroupsData({ isGroupCreating: false, groupsError: {creating: error} }));
  }
}

function* deleteGroupSaga(action) {
  try {
    //yield put(requestDog());
    const groupsById = yield select(state =>
      state.Catalog.getIn(['groupsById']).toJS(),
    );
    const deletedIds = action.payload.length ? (action.payload.splice(1, action.payload.length - 1)) : [];
    yield put(actions.setGroupsData({ isGroupDeleting: true }));
    yield call(() => api.delete(`audit_groups/${action.payload[0]}/${setRequestParams({ids: deletedIds.join(',')})}`));

    [...action.payload, ...deletedIds].forEach(groupId => {
      delete groupsById[groupId];
    });

    yield put(actions.setGroupsData({ groupsById }));
    yield put(actions.setGroupsData({ isGroupDeleting: false }));
  } catch (error) {
    console.log('Group loading error', error);
    yield put(actions.setGroupsData({ isGroupDeleting: false, groupsError: error }));
  }
}

function* updateGroupSaga(action) {
  try {
    //yield put(requestDog());
    const groupsById = yield select(state =>
      state.Catalog.getIn(['groupsById']).toJS(),
    );
    yield put(actions.setGroupsData({ isGroupUpdating: true }));
    const data = yield call(() => api.patch(`audit_groups/${action.payload.id}`, action.payload));
    yield put(actions.setGroupsData({ groupsById: {...groupsById, [data.id]: {...data, key: data.id}} }));
    yield put(actions.setGroupsData({ isGroupUpdating: false, groupUpdateProcessSuccessful: true }));
    yield put(actions.clearGroupData());
  } catch (error) {
    console.log('Groups loading error', error);
    yield put(actions.setGroupsData({ isGroupUpdating: false, groupsError: {updating: error} }));
  }
}



function* unitsRenderEffectSaga({ payload }) {
  try {
    //yield put(requestDog());
    yield put(actions.setUnitsData({ isUnitsFetching: true }));
    const data = yield call(() => api.get(`units/${setRequestParams(payload.params)}`));
    yield put(actions.setUnitsData({ unitsById: getObject(data) }));
    yield put(actions.setUnitsData({ isUnitsFetching: false }));
    //yield put(actions.clearUnitData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Units loading error');
    yield put(actions.setUnitsData({ isUnitsFetching: false, unitsError: error }));
  }
}

function* unitRenderEffectSaga({ payload }) {
  try {
    //yield put(requestDog());
    //let stateGroups = yield select(getCatalog);
    yield put(actions.setUnitsData({ isUnitsFetching: true }));
    const data = yield call(() => api.get('units'));
    yield put(actions.setUnitsData({ unitsById: data }));
    yield put(actions.setUnitsData({ isUnitsFetching: false }));
    //yield put(actions.clearUnitData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Units loading error');
    yield put(actions.setUnitsData({ isUnitsFetching: false, unitsError: error }));
  }
}

function* createUnitEffectSaga(action) {
  try {
    //yield put(requestDog());
    const unitsById = yield select(state =>
      state.Catalog.getIn(['unitsById']).toJS(),
    );
    yield put(actions.setUnitsData({ isUnitCreating: true }));
    const data = yield call(() => api.post('units', action.payload));
    yield put(actions.setUnitsData({ unitsById: {...unitsById, [data.id]: {...data, key: data.id}} }));
    yield put(actions.setUnitsData({ isUnitCreating: false, unitUpdateProcessSuccessful: true }));
    yield put(actions.clearUnitData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Units loading error');
    yield put(actions.setUnitsData({ isUnitCreating: false, unitsError: {creating: error} }));
  }
}

function* deleteUnitSaga(action) {
  try {
    //yield put(requestDog());
    const unitsById = yield select(state =>
      state.Catalog.getIn(['unitsById']).toJS(),
    );
    const deletedIds = action.payload.length ? (action.payload.splice(1, action.payload.length - 1)) : [];
    yield put(actions.setUnitsData({ isUnitDeleting: true }));
    yield call(() => api.delete(`units/${action.payload[0]}/${setRequestParams({ids: deletedIds.join(',')})}`));

    [...action.payload, ...deletedIds].forEach(unitId => {
      delete unitsById[unitId];
    });

    yield put(actions.setUnitsData({ unitsById }));
    yield put(actions.setUnitsData({ isUnitDeleting: false }));
  } catch (error) {
    console.log('Units loading error', error);
    yield put(actions.setUnitsData({ isUnitDeleting: false, unitsError: error }));
  }
}

function* updateUnitSaga(action) {
  try {
    //yield put(requestDog());
    const unitsById = yield select(state =>
      state.Catalog.getIn(['unitsById']).toJS(),
    );
    yield put(actions.setUnitsData({ isUnitUpdating: true }));
    const data = yield call(() => api.patch(`units/${action.payload.id}`, action.payload));
    yield put(actions.setUnitsData({ unitsById: {...unitsById, [data.id]: {...data, key: data.id}} }));
    yield put(actions.setUnitsData({ isUnitUpdating: false, unitUpdateProcessSuccessful: true }));
    yield put(actions.clearUnitData());
  } catch (error) {
    console.log('Units loading error', error);
    yield put(actions.setUnitsData({ isUnitUpdating: false, unitsError: {updating: error}  }));
  }
}



function* companiesRenderEffectSaga({ payload }) {
  try {
    //yield put(requestDog());
    yield put(actions.setCompaniesData({ isCompaniesFetching: true }));
    const data = yield call(() => api.get(`companies/${setRequestParams(payload.params)}`));
    yield put(actions.setCompaniesData({ companiesById: getObject(data) }));
    yield put(actions.setCompaniesData({ isCompaniesFetching: false }));
    //yield put(actions.clearUnitData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Company loading error');
    yield put(actions.setCompaniesData({ isCompaniesFetching: false, companiesError: error }));
  }
}

function* companyRenderEffectSaga({ payload }) {
  try {
    //yield put(requestDog());
    //let stateGroups = yield select(getCatalog);
    yield put(actions.setCompaniesData({ isCompaniesFetching: true }));
    const data = yield call(() => api.get('companies'));
    yield put(actions.setCompaniesData({ companiesById: data }));
    yield put(actions.setCompaniesData({ isCompaniesFetching: false }));
    //yield put(actions.clearUnitData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Company loading error');
    yield put(actions.setCompaniesData({ isCompaniesFetching: false, companiesError: error }));
  }
}

function* createCompanyEffectSaga(action) {
  try {
    //yield put(requestDog());
    const companiesById = yield select(state =>
      state.Catalog.getIn(['companiesById']).toJS(),
    );
    yield put(actions.setCompaniesData({ isCompanyCreating: true }));
    const data = yield call(() => api.post('companies', action.payload));
    yield put(actions.setCompaniesData({ companiesById: {...companiesById, [data.id]: {...data, key: data.id}} }));
    yield put(actions.setCompaniesData({ isCompanyCreating: false, companyUpdateProcessSuccessful: true }));
    yield put(actions.clearCompanyData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Company loading error');
    yield put(actions.setCompaniesData({ isCompanyCreating: false, companiesError: {creating: error} }));
  }
}

function* deleteCompanySaga(action) {
  try {
    //yield put(requestDog());
    const companiesById = yield select(state =>
      state.Catalog.getIn(['companiesById']).toJS(),
    );
    yield put(actions.setCompaniesData({ isCompanyDeleting: true }));

    const deletedIds = action.payload.length ? (action.payload.splice(1, action.payload.length - 1)) : [];
    yield call(() => api.delete(`companies/${action.payload[0]}/${setRequestParams({ids: deletedIds.join(',')})}`));

    [...action.payload, ...deletedIds].forEach(companyId => {
      delete companiesById[companyId];
    });

    yield put(actions.setCompaniesData({ companiesById }));
    yield put(actions.setCompaniesData({ isCompanyDeleting: false }));
  } catch (error) {
    console.log('Company loading error', error);
    yield put(actions.setCompaniesData({ isCompanyDeleting: false, companiesError: error }));
  }
}

function* updateCompanySaga(action) {
  try {
    //yield put(requestDog());
    const companiesById = yield select(state =>
      state.Catalog.getIn(['companiesById']).toJS(),
    );
    yield put(actions.setCompaniesData({ isCompanyUpdating: true }));
    const data = yield call(() => api.patch(`companies/${action.payload.id}`, action.payload));
    yield put(actions.setCompaniesData({ companiesById: {...companiesById, [data.id]: {...data, key: data.id}} }));
    yield put(actions.setCompaniesData({ isCompanyUpdating: false, companyUpdateProcessSuccessful: true }));
    yield put(actions.clearCompanyData());
  } catch (error) {
    console.log('Company loading error', error);
    yield put(actions.setCompaniesData({ isCompanyUpdating: false, companiesError: {updating: error}  }));
  }
}




function* typesRenderEffectSaga() {
  let typesById;
  typesById = DemoData.types;
  yield put(actions.setTypesData(typesById));
}

function* typeRenderEffectSaga({ payload }) {
  let typesById;
  typesById = DemoData.types;
  yield put(
    actions.setTypeData(typesById)
  );
}

function* createTypeEffectSaga(action) {
  let stateTypes = yield select(getCatalog);
  const typesById = {
    ...stateTypes.typesById,
    [action.payload.id]: action.payload,
  };
  yield put(actions.createType(typesById));
}

function* deleteTypeSaga(action) {
  let stateTypes = yield select(getCatalog);
  const typesById = {
    ...stateTypes.typesById,
  };
  delete typesById[action.payload];
  yield put(actions.deleteType(typesById));
}

function* updateTypeSaga(action) {
  let stateTypes = yield select(getCatalog);
  const typesById = {
    ...stateTypes.typesById,
    [action.payload.id]: action.payload
  };
  yield put(actions.updateType(typesById));
}



function* parametersRenderEffectSaga() {
  let parametersById;
  parametersById = DemoData.parameters;
  yield put(actions.setParametersData(parametersById));
}

function* parameterRenderEffectSaga({ payload }) {
  let parametersById;
  parametersById = DemoData.parameters;
  yield put(
    actions.setParameterData(parametersById)
  );
}

function* createParameterEffectSaga(action) {
  let stateParameters = yield select(getCatalog);
  const parametersById = {
    ...stateParameters.parametersById,
    [action.payload.id]: action.payload,
  };
  yield put(actions.createParameter(parametersById));
}

function* deleteParameterSaga(action) {
  let stateParameters = yield select(getCatalog);
  const parametersById = {
    ...stateParameters.parametersById,
  };
  delete parametersById[action.payload];
  yield put(actions.deleteParameter(parametersById));
}

function* updateParameterSaga(action) {
  let stateParameters = yield select(getCatalog);
  const parametersById = {
    ...stateParameters.parametersById,
    [action.payload.id]: action.payload
  };
  yield put(actions.updateParameter(parametersById));
}


export default function* catalogSaga() {
  yield all([
    takeEvery(actions.LOAD_GROUPS_DATA_SAGA, groupsRenderEffectSaga),
    takeEvery(actions.LOAD_GROUP_DATA_SAGA, groupRenderEffectSaga),
    takeEvery(actions.CREATE_GROUP_WATCHER, createGroupEffectSaga),
    takeEvery(actions.DELETE_GROUP_WATCHER, deleteGroupSaga),
    takeEvery(actions.UPDATE_GROUP_WATCHER, updateGroupSaga),

    takeEvery(actions.LOAD_UNITS_DATA_SAGA, unitsRenderEffectSaga),
    takeEvery(actions.LOAD_UNIT_DATA_SAGA, unitRenderEffectSaga),
    takeEvery(actions.CREATE_UNIT_WATCHER, createUnitEffectSaga),
    takeEvery(actions.DELETE_UNIT_WATCHER, deleteUnitSaga),
    takeEvery(actions.UPDATE_UNIT_WATCHER, updateUnitSaga),

    takeEvery(actions.LOAD_PARAMETERS_DATA_SAGA, parametersRenderEffectSaga),
    takeEvery(actions.LOAD_PARAMETER_DATA_SAGA, parameterRenderEffectSaga),
    takeEvery(actions.CREATE_PARAMETER_WATCHER, createParameterEffectSaga),
    takeEvery(actions.DELETE_PARAMETER_WATCHER, deleteParameterSaga),
    takeEvery(actions.UPDATE_PARAMETER_WATCHER, updateParameterSaga),

    takeEvery(actions.LOAD_TYPES_DATA_SAGA, typesRenderEffectSaga),
    takeEvery(actions.LOAD_TYPE_DATA_SAGA, typeRenderEffectSaga),
    takeEvery(actions.CREATE_TYPE_WATCHER, createTypeEffectSaga),
    takeEvery(actions.DELETE_TYPE_WATCHER, deleteTypeSaga),
    takeEvery(actions.UPDATE_TYPE_WATCHER, updateTypeSaga),

    takeEvery(actions.LOAD_COMPANIES_DATA_SAGA, companiesRenderEffectSaga),
    takeEvery(actions.LOAD_COMPANY_DATA_SAGA, companyRenderEffectSaga),
    takeEvery(actions.CREATE_COMPANY_WATCHER, createCompanyEffectSaga),
    takeEvery(actions.DELETE_COMPANY_WATCHER, deleteCompanySaga),
    takeEvery(actions.UPDATE_COMPANY_WATCHER, updateCompanySaga),

  ]);
}

