import {all, takeEvery, put, select, call} from 'redux-saga/effects';
import api from '@axicom/api';
import actions from './actions';
import { setRequestParams } from '@axicom/lib/helpers/utility';
import notification from "@iso/components/Notification";
import {createGroupEffectSaga} from '@axicom/redux/catalog/saga';


const projectObjectFormation = (cur) => ({
  id: cur.id,
  title: cur.name,
  number: cur.id,
  coordinators: cur.coordinators,
  coordinators_ids: cur.coordinators ? cur.coordinators.map(c => c.id) : [],
  company: cur.company,
  company_id: cur.company ? cur.company.id : undefined,
  address: cur.address,
  contacts: cur.contacts,
  notes: cur.notes,
  additional_fields: cur.additional_fields,
  progress: 50,
  editing: false,
  created_at: cur.created_at,
  creator: cur.creator || {},
  updated_at: '',
  key: cur.id
});


const projectCreatePayloadFormation = (project) => ({
  address: project.address,
  company_id: project.company_id,
  contacts: project.contacts,
  coordinator_ids: project.coordinators_ids,
  name: project.title,
  notes: project.notes,
  additional_fields: project.additional_fields
})


const projectsObjectFormation = (data, property = 'id') => {
  return data.reduce((obj, cur) => {
    obj[cur[property]] = projectObjectFormation(cur);
    return obj;
  }, {});
};


function* projectsRenderEffectSaga({ payload }) {
  try {
    //yield put(requestDog());
    yield put(actions.setProjectsData({ isProjectsFetching: true, isProjectCreatingSuccess: false, isDeleteSuccess: false }));
    const data = yield call(() => api.get(`customer_objects/${setRequestParams(payload.params)}`));
    yield put(actions.setProjectsData({ projectsById: projectsObjectFormation(data) }));
    yield put(actions.setProjectsData({ isProjectsFetching: false }));
    //yield put(actions.clearGroupData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Projects loading error');
    yield put(actions.setProjectsData({ isProjectsFetching: false, projectsError: error }));
  }
}

function* projectRenderEffectSaga({ payload }) {
  try {
    //yield put(requestDog());
    yield put(actions.setProjectsData({ isProjectFetching: true, isDeleteSuccess: false }));
    const data = yield call(() => api.get(`customer_objects/${ payload.id }`));
    yield put(actions.setProjectData({ project: projectObjectFormation(data)}));
    yield put(actions.setProjectsData({ isProjectFetching: false }));
    //yield put(actions.clearGroupData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Projects loading error');
    yield put(actions.setProjectsData({ isProjectFetching: false, projectsError: error }));
  }
}

function* createProjectEffectSaga(action) {
  try {
    //yield put(requestDog());
    const projectsById = yield select(state =>
      state.Projects.getIn(['projectsById']).toJS(),
    );
    yield put(actions.setProjectsData({ isProjectCreating: true, isDeleteSuccess: false }));
    const data = yield call(() => api.post('customer_objects', projectCreatePayloadFormation(action.payload)));
    yield put(actions.setProjectsData({ projectsById: {...projectsById, [data.id]: projectObjectFormation(data)} }));
    yield put(actions.setProjectData({ currentProject: projectObjectFormation(data) }))
    yield put(actions.setProjectsData({ isProjectCreating: false, isProjectCreatingSuccess: true, }));

    //yield put(actions.clearProjectData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Projects loading error');
    yield put(actions.setProjectsData({ isProjectCreating: false, projectsError: error }));
  }
}

function* deleteProjectEffectSaga(action) {
  try {
    //yield put(requestDog());
    const {projectsById, currentProject} = yield select(state =>
      state.Projects.getIn([]).toJS(),
    );

    yield put(actions.setProjectsData({ isProjectDeleting: true, isDeleteSuccess: false }));
    yield call(() => api.delete(`customer_objects/${action.payload}`));
    if (!!projectsById[action.payload]) {
      delete projectsById[action.payload];
      yield put(actions.setProjectsData({ projectsById }));
    }
    yield put(actions.setProjectsData({ isProjectDeleting: false, isDeleteSuccess: true }));
    notification('success', 'Объект заказчика успешно удален')

  } catch (error) {
    console.log('Projects loading error', error);
    yield put(actions.setProjectsData({ isProjectDeleting: false, projectsError: error }));
  }
}


function* updateProjectEffectSaga(action) {
  try {
    //yield put(requestDog());
    yield put(actions.setProjectsData({ isProjectUpdating: true, isDeleteSuccess: false }));
    const data = yield call(() => api.patch(`customer_objects/${action.payload.id}`, projectCreatePayloadFormation(action.payload)));
    yield put(actions.setProjectData({ project: projectObjectFormation(data) }));
    yield put(actions.setProjectsData({ isProjectUpdating: false }));
    yield put(actions.toggleView());
    //yield put(actions.clearProjectData());
  } catch (error) {
    console.log('Projects loading error', error);
    yield put(actions.setProjectsData({ isProjectUpdating: false, projectsError: error }));
  }
}



const getAuditGroup = (cur) => ({
  id: cur.id,
  name: cur.name,
  number: cur.id,
  customer_object: cur.customer_object,
  group: cur.audit_group,
  group_id: cur.audit_group ? cur.audit_group.id : undefined,
  created_at: cur.created_at,
  key: cur.id
});


const getCreateAuditGroupPayload = (auditGroup, projectId) => ({
  audit_group_id: auditGroup.id,
  customer_object_id: projectId,
  name: auditGroup.name,
})


const getAuditGroupsObject = (data, property = 'id') => {
  return data.reduce((obj, cur) => {
    obj[cur[property]] = getAuditGroup(cur);
    return obj;
  }, {});
};

function* auditGroupsRenderEffectSaga({ payload }) {
  try {
    //yield put(requestDog());
    yield put(actions.setAuditGroupsData({ isAuditGroupsFetching: true, isDeleteSuccess: false }));
    const data = yield call(() => api.get(`audit_group_instances/${setRequestParams(payload.params)}`));
    yield put(actions.setAuditGroupsData({ auditGroupsById: getAuditGroupsObject(data) }));
    yield put(actions.setAuditGroupsData({ isAuditGroupsFetching: false }));
    //yield put(actions.clearGroupData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Projects loading error');
    yield put(actions.setAuditGroupsData({ isAuditGroupsFetching: false, auditGroupsError: error }));
  }
}

function* addAuditGroupEffectSaga(action) {
  try {
    //yield put(requestDog());
    const auditGroupsById = yield select(state =>
      state.Projects.getIn(['auditGroupsById']).toJS(),
    );
    yield put(actions.setAuditGroupsData({ isAuditGroupCreating: true }));
    let data = {};
    if (action.payload.group.id) {
      data = yield call(() => api.post('audit_group_instances',
        getCreateAuditGroupPayload(action.payload.group, action.payload.projectId)));
    } else {
      const newGroup = yield call(() => createGroupEffectSaga({type: 'CREATE_GROUP_WATCHER', payload: action.payload.group}));
      data = yield call(() => api.post('audit_group_instances',
        getCreateAuditGroupPayload(newGroup, action.payload.projectId)));
      //data = newGroup //yield call(() => api.post('projects', action.payload));
    }
    yield put(actions.setAuditGroupsData({ auditGroupsById: {...auditGroupsById, [data.id]: getAuditGroup(data)} }));
    yield put(actions.setAuditGroupsData({ isAuditGroupCreating: false }));
    //yield put(actions.clearProjectData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Projects loading error');
    yield put(actions.setAuditGroupsData({ isAuditGroupCreating: false, auditGroupsError: error }));
  }
}

function* removeAuditGroupEffectSaga(action) {
  try {
    //yield put(requestDog());
    const auditGroupsById = yield select(state =>
      state.Projects.getIn(['auditGroupsById']).toJS(),
    );
    yield put(actions.setAuditGroupsData({ isAuditGroupDeleting: true }));
    yield call(() => api.delete(`audit_group_instances/${action.payload}`));
    if (!!auditGroupsById[action.payload]) {
      delete auditGroupsById[action.payload];
      yield put(actions.setAuditGroupsData({ auditGroupsById }));
    } else {
      //переход на страницу списка проектов
    }
    yield put(actions.setAuditGroupsData({ isAuditGroupDeleting: false }));
  } catch (error) {
    console.log('Projects loading error', error);
    yield put(actions.setAuditGroupsData({ isAuditGroupDeleting: false, auditGroupsError: error }));
  }
}




export default function* projectSaga() {
  yield all([
    takeEvery(actions.LOAD_PROJECTS_DATA_SAGA, projectsRenderEffectSaga),
    takeEvery(
      actions.LOAD_CURRENT_PROJECT_DATA_SAGA,
      projectRenderEffectSaga
    ),
    takeEvery(
      actions.CREATE_PROJECT_WATCHER,
      createProjectEffectSaga
    ),
    takeEvery(
      actions.DELETE_PROJECT_WATCHER,
      deleteProjectEffectSaga),
    takeEvery(actions.UPDATE_PROJECT_WATCHER, updateProjectEffectSaga),


    takeEvery(actions.LOAD_AUDIT_GROUPS_SAGA, auditGroupsRenderEffectSaga),
    takeEvery(actions.ADD_AUDIT_GROUP_WATCHER, addAuditGroupEffectSaga),
    takeEvery(actions.REMOVE_AUDIT_GROUP_WATCHER, removeAuditGroupEffectSaga),
  ]);
}

