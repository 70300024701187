import { Map } from 'immutable';
import Cookies from 'js-cookie';

export function clearToken() {
  Cookies.remove('axm_ad');
}

export function setToken(token) {
  Cookies.set('axm_ad', token);
};

export function getToken() {
  try {
    const idToken = Cookies.get('axm_ad');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export const setRequestParams = (data) => {
  if (!data) {
    return '';
  }
  if ( !Object.keys(data).length ) {
    return '';
  }
  return '?' + Object.keys(data).map(key => `${encodeURIComponent(key)}=${data[key]}`).join('&')
}

export const redirectTo = to => {
  window.location = to;
};

export function redirectToSignin(withBackUrl) {
  window.location = '/signin' + (withBackUrl ? '?next=' + encodeURIComponent(window.location) : '');
}

export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? 's' : '';
  };
  const number = (num) => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export const userObjectFormation = (user) => ({
  ...user,
  key: user.id,
  role: user.roles && user.roles[0] ? roleObjectFormation(user.roles[0]) : null,
  roleId: user.roles && user.roles[0] ? user.roles[0].id : null
});

export const roleObjectFormation = (role) => ({
  id: role.id,
  label: role.label,
  name: role.name,
  users_count: role.users_count || 0,
  permissionsById: role['permissions'].reduce((obj, p, ind) => ({...obj, [p.id]: ind}), {}),
  permissionsByName: role['permissions'].reduce((obj, p, ind) => ({...obj, [p.name]: p}), {}),
  permissions: role['permissions'],

  addUserRolesById: role['add_users_roles'].reduce((obj, p, ind) => ({...obj, [p.id]: ind}), {}),
  add_users_roles: role['add_users_roles'] || [],
  deleteUserRolesById: role['delete_users_roles'].reduce((obj, p, ind) => ({...obj, [p.id]: ind}), {}),
  delete_users_roles: role['delete_users_roles'] || [],
  editUserRolesById: role['edit_users_roles'].reduce((obj, p, ind) => ({...obj, [p.id]: ind}), {}),
  edit_users_roles: role['edit_users_roles'] || [],
  readUserRolesById: role['read_users_roles'].reduce((obj, p, ind) => ({...obj, [p.id]: ind}), {}),
  read_users_roles: role['read_users_roles'] || [],

  only_own_add_audit_group_audit: role['only_own_add_audit_group_instances'],
  only_own_delete_audit_group_audit: role['only_own_delete_audit_group_instances'],
  only_own_edit_audit_group_audit: role['only_own_edit_audit_group_instances'],

  only_own_delete_project: role['only_own_delete_customer_objects'],
  only_own_edit_project: role['only_own_edit_customer_objects'],
  only_own_read_project: role['only_own_read_customer_objects'],
})
