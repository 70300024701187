import actions from './actions';
import { fromJS } from 'immutable';

const initialUser = {
  full_name: '',
  role: undefined,
  username: '',
  contacts: '',
  password: '',
  roleId: undefined
}

const initialRole = {
  label: '',
  name: '',
  permissionsById: {},
  permissionsByName: {},
  permissions: [],

  addUserRolesById: {},
  add_users_roles: [],
  deleteUserRolesById: {},
  delete_users_roles: [],
  editUserRolesById: {},
  edit_users_roles: [],
  readUserRolesById: {},
  read_users_roles: [],

  only_own_add_audit_group_audit: true,
  only_own_delete_audit_group_audit: true,
  only_own_edit_audit_group_audit: true,

  only_own_delete_project: true,
  only_own_edit_project: true,
  only_own_read_project: true,
}

const initialState = fromJS({
  usersById: {},
  users: [],
  searchText: '',
  sortOrder: 'full_name',

  usersError: {},
  userUpdateProcessSuccessful: false,

  isUsersFetching: false,
  isUserCreating: false,
  isUserUpdating: false,
  isUserDeleting: false,

  isUserFetching: false,

  initialUsers: false,
  editableUser: {},
  isNewUser: false,

  roles: [],
  rolesById: {},
  rolesError: {},
  isRolesFetching: false,
  isRoleCreating: false,
  isRoleUpdating: false,
  isRoleDeleting: false,

  editRoleView: false,
  selectedRole: {},
  editableRole: initialRole,

  permissions: [],
  isPermissionsFetching: false,

});

const getUsersObject = (data, property = 'id') => {
  return data.reduce((obj, cur, ind) => {
    obj[cur[property]] = {
      id: cur.id,
      index: ind,
      full_name: cur.full_name
    };
    return obj;
  }, {});
};


export default function(state = initialState, action) {
  switch (action.type) {
    case actions.SET_USERS_DATA:
      return state
        .mergeDeep(action.payload)
        .updateIn(['users'], users =>
          fromJS(action.payload.users ? action.payload.users : users),
        )
        .updateIn(['usersById'], usersById =>
          fromJS(action.payload.users ? getUsersObject(action.payload.users) : usersById),
        )
        .updateIn(['userUpdateProcessSuccessful'], () =>
          fromJS(action.payload.userUpdateProcessSuccessful ? action.payload.userUpdateProcessSuccessful : false),
        )
        .updateIn(['usersError'], () =>
          fromJS(action.payload.usersError ? action.payload.usersError : {}) );

    case actions.SET_EDITABLE_USER_DATA:
      return state
        .updateIn(['editableUser'], () => action.payload )
        .updateIn(['userUpdateProcessSuccessful'], () => false)
        .updateIn(['usersError'], () =>
          fromJS(action.payload.usersError ? action.payload.usersError : {}) );

    case actions.CLEAR_USER_DATA:
      return state
        .updateIn(['editableUser'], () => initialUser )
        .updateIn(['userUpdateProcessSuccessful'], () => false)
        .updateIn(['usersError'], () => ({}) );

    case actions.SET_USER_SEARCH_TEXT:
      return state
        .updateIn(['searchText'], () => action.payload )
        .updateIn(['userUpdateProcessSuccessful'], () => false)
        .updateIn(['usersError'], () =>
          fromJS(action.payload.usersError ? action.payload.usersError : {}) );

    case actions.SET_SORT_ORDER:
      return state
        .updateIn(['sortOrder'], () => action.payload )
        .updateIn(['userUpdateProcessSuccessful'], () => false)
        .updateIn(['usersError'], () =>
          fromJS(action.payload.usersError ? action.payload.usersError : {}) );


    // Role
    case actions.SET_ROLES_DATA:
      //rolesById
      return state
        .mergeDeep(action.payload)
        .updateIn(['roles'], (roles) => fromJS(action.payload.roles ?
          action.payload.roles : roles))
        .updateIn(['rolesById'], (rolesById) => fromJS(action.payload.roles ?
          action.payload.roles.reduce((obj, cur) => ({...obj, [cur.id]: cur}), {}) : rolesById))
        .updateIn(['selectedRole'], (role) => fromJS(action.payload.selectedRole ?
          action.payload.selectedRole : (action.payload.roles ? action.payload.roles[0] : role)))

    case actions.SELECT_CURRENT_ROLE:
      return state
        .mergeDeep(action.payload)
        .updateIn(['selectedRole'], role =>
          fromJS(action.payload.role ? action.payload.role : role)
        )
        .updateIn(['editableRole'], role =>
          fromJS(action.payload.role ? (action.payload.role.id === role.id ? role : action.payload.role) : role)
        );

    case actions.SET_EDITABLE_ROLE_DATA:
      return state
        .mergeDeep(action.payload)
        .updateIn(['editableRole'], () =>
          fromJS(action.payload.role ? action.payload.role : initialRole),
        );

    case actions.CLEAR_ROLE_DATA:
      return state
        .mergeDeep(action.payload)
        .updateIn(['selectedRole'], () =>
          fromJS({}))
        .updateIn(['editableRole'], () =>
          fromJS(initialRole),
        );

    case actions.TOGGLE_ROLE_VIEW:
      return state
        .mergeDeep(action.payload)
        .updateIn(['editRoleView'], editRoleView =>
          fromJS(!editRoleView),
        );


    case actions.SET_PERMISSIONS_DATA:
      //isPermissionsFetching
      return state
        .mergeDeep(action.payload)
        .updateIn(['permissions'], (permissions) => fromJS(action.payload.permissions ?
          action.payload.permissions : permissions))


    default:
      return state;
  }
}
