const initialData = {
  groups: {
    'group-1': {
      id: 'group-1',
      key: 'group-1',
      name: 'Group 1'
    },
    'group-2': {
      id: 'group-2',
      key: 'group-2',
      name: 'Group 2'
    },
  },
  companies: {
    'company-1': {
      id: 'company-1',
      key: 'company-1',
      name: 'Company 1',
      address: 'Address',
      contacts: 'Contacts'
    },
    'company-2': {
      id: 'company-2',
      key: 'company-2',
      name: 'Company 2',
      address: 'Address',
      contacts: 'Contacts'
    },
  },
  parameters: {
    'parameter-1': {
      id: 'parameter-1',
      key: 'parameter-1',
      name: 'Parameter 1',
      units: ['unit-1', 'unit-2'],
      dependent_parameters: ['parameter-1', 'parameter-2'],
      device_types:  ['device_types-1', 'device_types-2'],
      object_types:  ['object_types-1', 'object_types-2'],
      opt_value: '16',
      min_value: '10',
      description: 'Description'
    },
    'parameter-2': {
      id: 'parameter-2',
      key: 'parameter-2',
      name: 'Parameter 2',
      units: ['unit-1', 'unit-2'],
      dependent_parameters: ['parameter-1', 'parameter-2'],
      device_types:  ['device_types-1', 'device_types-2'],
      object_types:  ['object_types-1', 'object_types-2'],
      opt_value: '12',
      min_value: '1',
      description: 'Description'
    },
  },
  types: {
    'type-1': {
      id: 'type-1',
      key: 'type-1',
      name: 'Device type 1',
      class: 'device',
      groups: ['group-1', 'group-2'],
      parameters: ['parameter-1', 'parameter-2'],
      description: 'Description'
    },
    'type-2': {
      id: 'type-2',
      key: 'type-2',
      name: 'Object type 2',
      class: 'object',
      groups: ['group-1', 'group-2'],
      parameters: ['parameter-1', 'parameter-2'],
      description: 'Description'
    },
  },
  units: {
    'unit-1': {
      id: 'unit-1',
      key: 'unit-1',
      name: 'Unit 1',
      designation: 'Designation',
      description: 'Description'
    },
    'unit-2': {
      id: 'unit-2',
      key: 'unit-2',
      name: 'Unit 2',
      designation: 'Designation',
      description: 'Description'
    },
  }
};

export default initialData;
