import axios from 'axios';
import notification from "@iso/components/Notification";
import siteConfig from '@axicom/config/site.config';
//import LanguageFactory from 'api/LanguageFactory';
import AuthHelper from '@axicom/lib/helpers/authHelper';
import { getToken, setToken, clearToken, redirectToSignin, redirectTo } from '@axicom/lib/helpers/utility';
import errorsConfig from '@axicom/config/errors.config';
import {PUBLIC_ROUTE} from "../route.constants";


const customHeader = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: 'Bearer ' + getToken().get('idToken') || undefined,
});

const METHODS_WITH_BODY = ['POST', 'PUT', 'PATCH', 'DELETE']

async function base (method, url, data = {}, onwHeader = null) {
  let options = {
    method,
    headers: onwHeader || customHeader(),
  }
  if (METHODS_WITH_BODY.includes(method)) {
    options.body = JSON.stringify(data);
  }
  return await fetch(`${siteConfig.apiUrl}${url}`, options)
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw response;
      }
    })
    .then(resp => {
      return resp
    })
    .catch(error => {

      if (error.status === 403) {
        if (getToken().get('idToken')) {
          const expireTokenError = (AuthHelper.checkExpirity(getToken().get('idToken')))['error'];
          if (expireTokenError) {
            redirectToSignin();
          }
          return error.text()
            .then((errorText) => {
              if (errorText) {
                let error = JSON.parse(errorText);
                if (error.error.code === errorsConfig.notAccess) {
                  notification('error', 'Не достаточно прав для выполнения операции');
                  throw {...error.error};
                }
              } else {
                if (PUBLIC_ROUTE.SIGN_IN !== window.location.pathname) {
                  notification('error', 'Подтвердите заново учетную запись');
                }
                throw {code: 10001};
              }
            })
            .catch((error) => {
              if (error.code !== errorsConfig.notAccess) {
                if (PUBLIC_ROUTE.SIGN_IN !== window.location.pathname) {
                  redirectToSignin();
                }
              }
              throw error;
            });
        } else {
          if (PUBLIC_ROUTE.SIGN_IN !== window.location.pathname) {
            redirectToSignin();
          }
          throw {code: errorsConfig.notAuth};
        }
      } else {
        if (error && (500 <= error.status && error.status < 600)) {
          //Alert.error('Oops.. Some problem with the server');
          if (error.status === 500) {
            return error.text()
              .then((errorText) => {
                let error = JSON.parse(errorText);
                throw {...error.error, code: 500};
                //return Promise.reject(text);
              })
          }
        } else {
          return error.text()
            .then((errorText) => {
              console.log(errorText)
              if (errorText) {
                let error = JSON.parse(errorText);
                throw {...error.error, code: error.error.code || 500};
              }
              //return Promise.reject(text);
            })
        }
      }
    });
};

const SuperFetch = {};
['get', 'post', 'put', 'patch', 'delete'].forEach(method => {
  SuperFetch[method] = base.bind(null, method.toUpperCase());
});
export default SuperFetch;


// const client = axios.create({
//   baseURL: siteConfig.apiUrl,
//   headers: {
//     'Authorization': getToken().get('idToken')  ? `Bearer ${ getToken().get('idToken') }` : null,
//     //'Accept-Language': LanguageFactory.getLocale(),
//     'Content-Type': 'application/json'
//   },
//   withCredentials: true
// });
//
// client.interceptors.response.use(
//   resp => {
//     if (resp.data && resp.data.status === 'error') {
//       return Promise.reject(resp.data);
//     } else {
//       if (resp.headers['content-disposition']) {
//         let [, fileName] = resp.headers['content-disposition'].split('filename=');
//         fileName = fileName.substring(1, fileName.length - 1);
//
//         return {
//           fileName: fileName,
//           mimeType: resp.headers['content-type'].split(';')[0],
//           data: resp.data
//         };
//
//       } else {
//         return resp.data;
//       }
//     }
//   },
//   error => {
//     if (error.response && error.response.status === 401) {
//       clearToken();
//       //redirectToSignin();
//       //window.redirectToSignin(true);
//     }
//     if (error.response && (500 <= error.response.status && error.response.status < 600)) {
//       //Alert.error('Oops.. Some problem with the server');
//     }
//     return Promise.reject(error.response.data);
//   });
//
// export default client;



