import actions from './actions';

const initState = { idToken: null, isAuth: false, authError: null, currentUser: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        authError: null,
        isAuth: action.payload,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        authError: action.payload,
        isAuth: false
      };
    case actions.LOGOUT:
      return initState;
    case actions.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    default:
      return state;
  }
}
