import {all, takeEvery, put, fork, call} from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import api from '@axicom/api';
import AuthHelper from '@axicom/lib/helpers/authHelper';
import errorsConfig from '@axicom/config/errors.config';
import { PUBLIC_ROUTE } from '../../route.constants';


import { getToken, setToken, clearToken, userObjectFormation, redirectToSignin } from '@axicom/lib/helpers/utility';
import actions from './actions';

const history = createBrowserHistory();

export function* getCurrentUser() {
  yield takeEvery(actions.GET_CURRENT_USER, function*() {
    try {
      const data = yield call(() => api.get(`auth/user`));
      yield put({ type: actions.SET_CURRENT_USER, payload: userObjectFormation(data) });
    } catch(error) {
      if (errorsConfig.notAuth !== error.code) {
        yield put({ type: actions.LOGIN_ERROR, payload: error });
      }
    }
  });
}


export function* loginRequest() {
  yield takeEvery('LOGIN', function*({ payload }) {
    try {
      yield put({ type: actions.LOGIN_REQUEST, payload: true });

      const data = yield call(() => api.post('auth/login', payload, { 'Content-Type': 'application/json' }));

      //console.log('data.token', `Bearer ${data.token}`)
      //yield call( () => api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`);
      yield put({ type: actions.LOGIN_SUCCESS, token: data.token });

      yield put({ type: actions.LOGIN_REQUEST, payload: false });
    } catch (error) {
      yield put({ type: actions.LOGIN_ERROR, payload: error });
    }
    const { token } = '';
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield setToken(payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield call(() => api.post(`auth/logout`));
    yield call(() => redirectToSignin());
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken');
    if (token) {
      const tokenError = (AuthHelper.checkExpirity(token))['error'];
      if (tokenError) {
        if (PUBLIC_ROUTE.SIGN_IN !== window.location.pathname) {
          redirectToSignin();
        } else {
          yield put({ type: actions.LOGIN_ERROR, payload: tokenError });
        }
      } else {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token,
          profile: 'Profile',
        });
      }

    } else {

    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(getCurrentUser),
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
