export const DEVICES_CLASS_VALUE = 'devices'
export const OBJECTS_CLASS_VALUE = 'objects'

export const TYPE_OPTIONS = [{value: DEVICES_CLASS_VALUE, label: 'Устройства'}, {value: OBJECTS_CLASS_VALUE, label: 'Объекты'}]

//roles and permissions

export const roleWithoutPrivileges = 'ROLE_WITHOUT_PRIVILEGES';
export const roleAuditor = 'ROLE_AUDITOR';

export const permissionsConst = {
  readProjects: 'READ_CUSTOMER_OBJECTS',
  addProjects: 'ADD_CUSTOMER_OBJECTS',
  editProjects: 'EDIT_CUSTOMER_OBJECTS',
  deleteProjects: 'DELETE_CUSTOMER_OBJECTS',

  readRoles: 'READ_ROLES',
  addRoles: 'ADD_ROLES',
  editRoles: 'EDIT_ROLES',
  deleteRoles: 'DELETE_ROLES',

  readUsers: 'READ_USERS',
  addUsers: 'ADD_USERS',
  editUsers: 'EDIT_USERS',
  deleteUsers: 'DELETE_USERS',

  addGroups: 'ADD_AUDIT_GROUP_INSTANCES',
  deleteGroups: 'DELETE_AUDIT_GROUP_INSTANCES',
  editGroups: 'EDIT_AUDIT_GROUP_INSTANCES',

  readDirectories: 'READ_DIRECTORIES',
  editDirectories: 'EDIT_DIRECTORIES',
  addDirectories: 'ADD_DIRECTORIES',
  deleteDirectories: 'DELETE_DIRECTORIES',
};

export const permissionsForProject = [ permissionsConst.readProjects ];

export const dependentProjectPermissions = [ permissionsConst.addProjects, permissionsConst.editProjects,
  permissionsConst.deleteProjects, permissionsConst.addGroups, permissionsConst.editGroups,
  permissionsConst.deleteGroups ];

export const dependentDirectoriesPermissions = [ permissionsConst.editDirectories,
  permissionsConst.addDirectories, permissionsConst.deleteDirectories ];

export const dependentUsersPermissions = [ permissionsConst.readUsers, permissionsConst.deleteUsers,
  permissionsConst.addUsers, permissionsConst.editUsers ];