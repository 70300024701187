import { combineReducers } from 'redux';
import App from '@axicom/redux/app/reducer';
import Auth from '@axicom/redux/auth/reducer';
import ThemeSwitcher from '@axicom/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@axicom/redux/languageSwitcher/reducer';
import Projects from '@axicom/redux/projects/reducer';
import Users from '@axicom/redux/users/reducer';
import Catalog from '@axicom/redux/catalog/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Projects,
  Users,
  Catalog
});
