import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';


const GlobalStyles = createGlobalStyle`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8 !important;
  }
  td.ant-table-column-sort {
    background: transparent!important;
  }
  .red-row {
    background-color: #eccaca8c;
  }

  .ant-row.ant-form-item {
    margin-bottom: 15px;
  }

  .ant-popover-message-title {
    word-break: break-word;
    max-width: 400px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;

      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;

      .ant-checkbox-group-item {
        margin: 5px 0;

        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for chekbox [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }

  /*-----------------------------------------------*/
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;

            h2 {
              margin: 0;
            }

            form {
              padding: 15px 0 3px;

              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


  /*-----------------------------------------------*/
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8 !important;
  }

  font-family: ${font('primary', 0)}

  ;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette('primary', 0)};
      color: #fff;
    }
  }

  .flex-block {
    display: flex;
    flex-direction: row;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .error-message {
    color: #ff4d4f;
    text-align: start;
    margin-top: 3px;

  }

  .margin-right {
    margin-right: 8px;
  }

  .margin-left {
    margin-left: 8px;
  }

  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .ant-progress-inner {
    background-color: #b8b8b8 !important;
  }

  .ant-row:not(.ant-form-item) {${'' /* margin-left: -8px;
  margin-right: -8px; */};

    &:before,
    &:after {
      display: none;
    }
  }

  .ant-row > div {
    padding: 0;
  }

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .icon-button.icon-button-small {
    padding: 0 8px !important;
  }

  .icon-button.icon-button-middle {
    padding: 0 10px !important;
  }

  .icon-button .small-icon {
    font-size: 18px;
  }

  .icon-button .middle-icon {
    font-size: 22px;
  }

  .icon-button .large-icon {
    font-size: 26px;
    line-height: normal;
  }

  .editing-form {
    padding: 0;
  }

  .editing-form .ant-form {
    width: 100%;
  }

  .editing-form .ant-select-selection-item {
    text-overflow: ellipsis;
    max-width: 410px;
  }

`;

export default GlobalStyles;
