import {all, takeEvery, put, select, call} from 'redux-saga/effects';
import api from '@axicom/api';
import actions from './actions';
import notification from "@iso/components/Notification";
import { setRequestParams, userObjectFormation, roleObjectFormation } from '@axicom/lib/helpers/utility';
import {roleWithoutPrivileges} from '@axicom/constants';



const getUsers = (data, property) => {
  return data.map(user => {
    return userObjectFormation(user)
  })
}

const getRequestUser = (user) => ({
  ...user,
  role_ids: [user.roleId]
});


function* usersRenderEffectSaga({payload}) {
  try {
    //yield put(requestDog());
    yield put(actions.setUsersData({ isUsersFetching: true }));
    const data = yield call(() => api.get(`users/${setRequestParams(payload.params)}`));
    yield put(actions.setUsersData({ users: getUsers(data) }));
    yield put(actions.setUsersData({ isUsersFetching: false }));
    //yield put(actions.clearGroupData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Users loading error', error);
    yield put(actions.setUsersData({ isUsersFetching: false, usersError: error }));
  }
}

function* userRenderEffectSaga({ payload }) {
  try {
    //yield put(requestDog());
    const {users, usersById} = yield select(state =>
      state.Users.getIn([]).toJS(),
    );
    yield put(actions.setUsersData({ isUserFetching: true }));
    const data = yield call(() => api.get(`users/${ payload.id }`));
    users[users.findIndex(u => u.id === data.id)] = userObjectFormation(data);
    yield put(actions.setUsersData({ users: [ ...users ]}));
    yield put(actions.setUsersData({ isUserFetching: false }));
    //yield put(actions.clearGroupData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Users loading error');
    yield put(actions.setUsersData({ isUserFetching: false, usersError: error }));
  }
}

function* createUserEffectSaga(action) {
  try {
    //yield put(requestDog());
    const users = yield select(state =>
      state.Users.getIn(['users']).toJS(),
    );
    yield put(actions.setUsersData({ isUserCreating: true }));
    const data = yield call(() => api.post('users', getRequestUser(action.payload)));
    users.push(userObjectFormation(data));
    yield put(actions.setUsersData({ users: [ ...users ]}));
    yield put(actions.setUsersData({ isUserCreating: false, userUpdateProcessSuccessful: true }));
    yield put(actions.clearUserData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Users loading error', error);
    yield put(actions.setUsersData({ isUserCreating: false, usersError:  {creating: error} }));
  }
}

function* deleteUserSaga(action) {
  try {
    //yield put(requestDog());
    const users = yield select(state =>
      state.Users.getIn(['users']).toJS(),
    );

    yield put(actions.setUsersData({ isUserDeleting: true }));
    const deletedIds = action.payload.length ? (action.payload.splice(1, action.payload.length - 1)) : [];
    yield call(() => api.delete(`users/${action.payload[0]}/${setRequestParams({ids: deletedIds.join(',')})}`));

    [...deletedIds, action.payload[0]].forEach(data => {
      const removedIndex = users.findIndex(u => u.id === data);
      if (removedIndex !== -1) {
        users.splice(removedIndex, 1);
      }
    });

    yield put(actions.setUsersData({ users }));
    yield put(actions.setUsersData({ isUserDeleting: false }));
  } catch (error) {
    console.log('Users loading error', error);
    yield put(actions.setUsersData({ isUserDeleting: false, usersError: error }));
  }
}

function* updateUserSaga(action) {
  try {
    //yield put(requestDog());
    const users = yield select(state =>
      state.Users.getIn(['users']).toJS(),
    );
    yield put(actions.setUsersData({ isUserUpdating: true }));
    const data = yield call(() => api.patch(`users/${action.payload.id}`, getRequestUser(action.payload.user)));
    users[users.findIndex(u => u.id === data.id)] = userObjectFormation(data);
    yield put(actions.setUsersData({ users: [ ...users ]}));
    yield put(actions.setUsersData({ isUserUpdating: false, userUpdateProcessSuccessful: true }));
    yield put(actions.clearUserData());
  } catch (error) {
    console.log('Users loading error', error);
    yield put(actions.setUsersData({ isUserUpdating: false, usersError:  {updating: error} }));
  }
}

const requestRoleObjectFormation = (role) => ({
  id: role.id,
  label: role.label,
  name: role.name,
  permission_ids: role.permissions.map(p => p.id),
  add_users_role_ids: role.add_users_roles.map(p => p.id),
  delete_users_role_ids: role.delete_users_roles.map(p => p.id),
  edit_users_role_ids: role.edit_users_roles.map(p => p.id),
  read_users_role_ids: role.read_users_roles.map(p => p.id),

  only_own_add_audit_group_instances: role.only_own_add_audit_group_audit,
  only_own_delete_audit_group_instances: role.only_own_delete_audit_group_audit,
  only_own_edit_audit_group_instances: role.only_own_edit_audit_group_audit,

  only_own_delete_customer_objects: role.only_own_delete_project,
  only_own_edit_customer_objects: role.only_own_edit_project,
  only_own_read_customer_objects: role.only_own_read_project,
})

// ROLES
function* rolesRenderEffectSaga() {
  try {
    //yield put(requestDog());
    yield put(actions.setRolesData({ isRolesFetching: true }));
    let selectedRole = yield select(state =>
      state.Users.getIn(['selectedRole']).toJS(),
    );
    const data = yield call(() => api.get('roles'));
    const roles = data.map(role => roleObjectFormation(role));
    yield put(actions.setRolesData({ roles: roles }));
    yield put(actions.setRolesData({ isRolesFetching: false }));
    if (!selectedRole.id || roles.length) {
      yield put(actions.roleRenderWatcher(roles[0].id));
    }
    //yield put(actions.clearGroupData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Roles loading error');
    yield put(actions.setRolesData({ isRolesFetching: false, rolesError: error }));
  }
}

function* roleRenderEffectSaga(action) {
  try {
    //yield put(requestDog());
    yield put(actions.setRolesData({ isRoleFetching: true }));
    let roles = yield select(state =>
      state.Users.getIn(['roles']).toJS(),
    );
    const index = roles.findIndex(r => r.id === action.payload);
    yield put(actions.setSelectedRole(roles[index]));
    const data = yield call(() => api.get(`roles/${action.payload}`));
    const role = roleObjectFormation(data);
    roles[index] = role;
    yield put(actions.setRolesData({ roles: roles }));
    yield put(actions.setSelectedRole(role));
    yield put(actions.setRolesData({ isRoleFetching: false }));
    //yield put(actions.clearGroupData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Roles loading error');
    yield put(actions.setRolesData({ isRoleFetching: false, rolesError: error }));
  }
}


function* createRoleEffectSaga(action) {
  try {
    //yield put(requestDog());
    const roles = yield select(state =>
      state.Users.getIn(['roles']).toJS(),
    );
    yield put(actions.setRolesData({ isRoleCreating: true }));
    const data = yield call(() => api.post('roles', requestRoleObjectFormation(action.payload)));
    const role = roleObjectFormation(data)
    roles.push(role);
    yield put(actions.setRolesData({ roles: roles}));
    yield put(actions.setRolesData({ isRoleCreating: false, roleUpdateProcessSuccessful: true }));

    notification('success', 'Роль успешно создана')
    yield put(actions.setSelectedRole(role));
    yield put(actions.toggleRoleView());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Roles loading error');
    yield put(actions.setPermissionsData({ isRoleCreating: false, rolesError:  {creating: error} }));
  }
}

function* deleteRoleSaga(action) {
  try {
    //yield put(requestDog());
    const roles = yield select(state =>
      state.Users.getIn(['roles']).toJS(),
    );

    yield put(actions.setRolesData({ isRoleDeleting: true }));
    const deletedId = action.payload.roleId;
    const newUsersRoleId = action.payload.newUsersRoleId;
    yield call(() => api.delete(`roles/${deletedId}?new_role_id=${newUsersRoleId}`));

    const removedIndex = roles.findIndex(r => r.id === deletedId);
    const deletedRole = roles[removedIndex];
    if (removedIndex !== -1) {
      roles.splice(removedIndex, 1);
    }
    let newUsersRoleIndex = roles.findIndex(r => r.id === newUsersRoleId);
    if (roleWithoutPrivileges !== roles[newUsersRoleIndex].name) {
      roles[newUsersRoleIndex].users_count += deletedRole.users_count;
    } else {
      if (roleWithoutPrivileges !== roles[0].name) {
        newUsersRoleIndex = 0;
        roles[newUsersRoleIndex].users_count += deletedRole.users_count;
      } else {
        if (roles.length > 1) {
          newUsersRoleIndex = roles.length - 1;
        } else {
          newUsersRoleIndex = -1;
        }
      }
    }

    yield put(actions.setRolesData({ roles }));
    yield put(actions.clearRoleData());
    yield put(actions.setSelectedRole(roles[newUsersRoleIndex] || null));
    yield put(actions.setRolesData({ isRoleDeleting: false }));
    notification('success',  `Роль ${deletedRole.label || ''} успешно удалена`);
  } catch (error) {
    console.log('Roles loading error', error);
    yield put(actions.setRolesData({ isRoleDeleting: false, rolesError: error }));
  }
}

function* updateRoleSaga(action) {
  try {
    //yield put(requestDog());
    const roles = yield select(state =>
      state.Users.getIn(['roles']).toJS(),
    );
    yield put(actions.setRolesData({ isRoleUpdating: true }));
    const data = yield call(() => api.patch(`roles/${action.payload.id}`, requestRoleObjectFormation(action.payload.role)));
    const role = roleObjectFormation(data);
    const oldRoleData = roles[roles.findIndex(u => u.id === data.id)];
    roles[roles.findIndex(u => u.id === data.id)] = {...role, users_count: oldRoleData.users_count};
    yield put(actions.setRolesData({ roles}));
    yield put(actions.setRolesData({ isRoleUpdating: false, roleUpdateProcessSuccessful: true }));
    notification('success', 'Роль успешно обновлена')
    yield put(actions.setSelectedRole( {...role, users_count: oldRoleData.users_count}));
    yield put(actions.toggleRoleView());
  } catch (error) {
    console.log('Roles loading error', error);
    yield put(actions.setRolesData({ isRoleUpdating: false, rolesError:  {updating: error} }));
  }
}



// PERMISSIONS
function* permissionsRenderEffectSaga() {
  try {
    //yield put(requestDog());
    yield put(actions.setPermissionsData({ isPermissionsFetching: true }));
    const data = yield call(() => api.get('roles/permissions'));
    yield put(actions.setPermissionsData({ permissions: data }));
    yield put(actions.setPermissionsData({ isPermissionsFetching: false }));
    //yield put(actions.clearGroupData());
    //yield put(actions.setGroupsData(groupsById))
  } catch (error) {
    console.log('Permissions loading error');
    yield put(actions.setPermissionsData({ isPermissionsFetching: false, usersError: error }));
  }
}



export default function* usersSaga() {
  yield all([
    takeEvery(actions.LOAD_USERS_DATA_SAGA, usersRenderEffectSaga),
    takeEvery(actions.LOAD_ROLES_SAGA, rolesRenderEffectSaga),
    takeEvery(actions.LOAD_PERMISSIONS_SAGA, permissionsRenderEffectSaga),
    // takeEvery(
    //   actions.LOAD_CURRENT_USER_DATA_SAGA,
    //   userRenderEffectSaga
    // ),

    takeEvery(actions.CREATE_USER_WATCHER, createUserEffectSaga),
    takeEvery(actions.DELETE_USER_WATCHER, deleteUserSaga),
    takeEvery(actions.UPDATE_USER_WATCHER, updateUserSaga),

    takeEvery(actions.LOAD_CURRENT_ROLE_DATA_SAGA, roleRenderEffectSaga),
    takeEvery(actions.CREATE_ROLE_WATCHER, createRoleEffectSaga),
    takeEvery(actions.DELETE_ROLE_WATCHER, deleteRoleSaga),
    takeEvery(actions.UPDATE_ROLE_WATCHER, updateRoleSaga),

  ]);
}

