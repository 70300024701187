export default {
  undefinedError: 500,
  tokenExpired: 10001,

  loginOrPassword: 1000,

  notAccess: 1001,
  notAuth: 1011,

  groupExist: 3000,
  userExist: 3001,
  companyExist: 3002,
  unitExist: 3003,

  selectRole: 30002

}