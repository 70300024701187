const actions = {
  //SEARCH
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  setSearchText: payload => ({
    type: actions.SET_SEARCH_TEXT,
    payload,
  }),


  //GROUPS
  LOAD_GROUPS_DATA_SAGA: 'LOAD_GROUPS_DATA_SAGA',
  SET_GROUPS_DATA: 'SET_GROUPS_DATA',
  LOAD_GROUP_DATA_SAGA: 'LOAD_GROUP_DATA_SAGA',
  SET_GROUP_DATA: 'SET_GROUP_DATA',

  CLEAR_GROUP_DATA: 'CLEAR_GROUP_DATA',
  SET_EDITABLE_GROUP_DATA: 'SET_EDITABLE_GROUP_DATA',

  CREATE_GROUP_WATCHER: 'CREATE_GROUP_WATCHER',
  CREATE_GROUP: 'CREATE_GROUP',

  DELETE_GROUP_WATCHER: 'DELETE_GROUP_WATCHER',
  DELETE_GROUP: 'DELETE_GROUP_WATCHER',

  UPDATE_GROUP_WATCHER: 'UPDATE_GROUP_WATCHER',
  UPDATE_GROUP: 'UPDATE_GROUP',

  
  // Groups Actions
  groupsRenderWatcher: (params = {}) => ({ type: actions.LOAD_GROUPS_DATA_SAGA, payload: params }),
  setGroupsData: payload =>({
    type: actions.SET_GROUPS_DATA,
    payload,
  }),
  
  groupRenderWatcher: groupId => ({
    type: actions.LOAD_GROUP_DATA_SAGA,
    payload: groupId,
  }),
  setGroupData: groupData => ({
    type: actions.SET_GROUP_DATA,
    payload: groupData,
  }),

  setEditableGroup: group => ({type: actions.SET_EDITABLE_GROUP_DATA, payload: group}),
  clearGroupData: () => ({type: actions.CLEAR_GROUP_DATA}),

  createGroupWatcher: group => ({
    type: actions.CREATE_GROUP_WATCHER,
    payload: group,
  }),

  deleteGroupWatcher: groupsID => ({
    type: actions.DELETE_GROUP_WATCHER,
    payload: groupsID,
  }),

  updateGroupWatcher: group => ({
    type: actions.UPDATE_GROUP_WATCHER,
    payload: group,
  }),


  //UNITS
  LOAD_UNITS_DATA_SAGA: 'LOAD_UNITS_DATA_SAGA',
  SET_UNITS_DATA: 'SET_UNITS_DATA',
  LOAD_UNIT_DATA_SAGA: 'LOAD_UNIT_DATA_SAGA',
  SET_UNIT_DATA: 'SET_UNIT_DATA',

  CLEAR_UNIT_DATA: 'CLEAR_UNIT_DATA',
  SET_EDITABLE_UNIT_DATA: 'SET_EDITABLE_UNIT_DATA',

  CREATE_UNIT_WATCHER: 'CREATE_UNIT_WATCHER',
  CREATE_UNIT: 'CREATE_UNIT',

  DELETE_UNIT_WATCHER: 'DELETE_UNIT_WATCHER',
  DELETE_UNIT: 'DELETE_UNIT_WATCHER',

  UPDATE_UNIT_WATCHER: 'UPDATE_UNIT_WATCHER',
  UPDATE_UNIT: 'UPDATE_UNIT',

  //SET_UNIT_SEARCH_TEXT: 'SET_UNIT_SEARCH_TEXT',

  // Units Actions
  unitsRenderWatcher: (params = {}) => ({ type: actions.LOAD_UNITS_DATA_SAGA, payload: params }),
  setUnitsData: payload =>({
    type: actions.SET_UNITS_DATA,
    payload,
  }),

  unitRenderWatcher: unitId => ({
    type: actions.LOAD_UNIT_DATA_SAGA,
    payload: unitId,
  }),
  setUnitData: payload => ({
    type: actions.SET_UNIT_DATA,
    payload,
  }),

  setEditableUnit: unit => ({type: actions.SET_EDITABLE_UNIT_DATA, payload: unit}),
  clearUnitData: () => ({type: actions.CLEAR_UNIT_DATA}),

  // setUnitSearchText: payload => ({
  //   type: actions.SET_UNIT_SEARCH_TEXT,
  //   payload,
  // }),

  createUnitWatcher: unit => ({
    type: actions.CREATE_UNIT_WATCHER,
    payload: unit,
  }),

  deleteUnitWatcher: unitID => ({
    type: actions.DELETE_UNIT_WATCHER,
    payload: unitID,
  }),

  updateUnitWatcher: unit => ({
    type: actions.UPDATE_UNIT_WATCHER,
    payload: unit,
  }),


  // COMPANIES
  LOAD_COMPANIES_DATA_SAGA: 'LOAD_COMPANIES_DATA_SAGA',
  SET_COMPANIES_DATA: 'SET_COMPANIES_DATA',
  LOAD_COMPANY_DATA_SAGA: 'LOAD_COMPANY_DATA_SAGA',
  SET_COMPANY_DATA: 'SET_COMPANY_DATA',

  CLEAR_COMPANY_DATA: 'CLEAR_COMPANY_DATA',
  SET_EDITABLE_COMPANY_DATA: 'SET_EDITABLE_COMPANY_DATA',

  CREATE_COMPANY_WATCHER: 'CREATE_COMPANY_WATCHER',
  CREATE_COMPANY: 'CREATE_COMPANY',

  DELETE_COMPANY_WATCHER: 'DELETE_COMPANY_WATCHER',
  DELETE_COMPANY: 'DELETE_COMPANY',

  UPDATE_COMPANY_WATCHER: 'UPDATE_COMPANY_WATCHER',
  UPDATE_COMPANY: 'UPDATE_COMPANY',

  //SET_COMPANY_SEARCH_TEXT: 'SET_COMPANY_SEARCH_TEXT',

  // Companies Actions
  companiesRenderWatcher:(params = {}) => ({ type: actions.LOAD_COMPANIES_DATA_SAGA, payload: params }),
  setCompaniesData: payload =>({
    type: actions.SET_COMPANIES_DATA,
    payload,
  }),

  companyRenderWatcher: companyId => ({
    type: actions.LOAD_COMPANY_DATA_SAGA,
    payload: companyId,
  }),
  setCompanyData: payload => ({
    type: actions.SET_COMPANY_DATA,
    payload,
  }),

  setEditableCompany: company => ({type: actions.SET_EDITABLE_COMPANY_DATA, payload: company}),
  clearCompanyData: () => ({type: actions.CLEAR_COMPANY_DATA}),

  /*  setCompanySearchText: payload => ({
      type: actions.SET_COMPANY_SEARCH_TEXT,
      payload,
    }),*/

  createCompanyWatcher: company => ({
    type: actions.CREATE_COMPANY_WATCHER,
    payload: company,
  }),

  deleteCompanyWatcher: companyID => ({
    type: actions.DELETE_COMPANY_WATCHER,
    payload: companyID,
  }),

  updateCompanyWatcher: company => ({
    type: actions.UPDATE_COMPANY_WATCHER,
    payload: company,
  }),


  // TYPES
  LOAD_TYPES_DATA_SAGA: 'LOAD_TYPES_DATA_SAGA',
  SET_TYPES_DATA: 'SET_TYPES_DATA',
  LOAD_TYPE_DATA_SAGA: 'LOAD_TYPE_DATA_SAGA',
  SET_TYPE_DATA: 'SET_TYPE_DATA',

  CREATE_TYPE_WATCHER: 'CREATE_TYPE_WATCHER',
  CREATE_TYPE: 'CREATE_TYPE',

  DELETE_TYPE_WATCHER: 'DELETE_TYPE_WATCHER',
  DELETE_TYPE: 'DELETE_TYPE_WATCHER',

  UPDATE_TYPE_WATCHER: 'UPDATE_TYPE_WATCHER',
  UPDATE_TYPE: 'UPDATE_TYPE',

  //SET_TYPE_SEARCH_TEXT: 'SET_TYPE_SEARCH_TEXT',

  // Types Actions
  typesRenderWatcher: () => ({ type: actions.LOAD_TYPES_DATA_SAGA }),
  setTypesData: typesById =>({
    type: actions.SET_TYPES_DATA,
    payload: typesById,
  }),

  typeRenderWatcher: typeId => ({
    type: actions.LOAD_TYPE_DATA_SAGA,
    payload: typeId,
  }),
  setTypeData: typeData => ({
    type: actions.SET_TYPE_DATA,
    payload: typeData,
  }),

  // setTypeSearchText: payload => ({
  //   type: actions.SET_TYPE_SEARCH_TEXT,
  //   payload,
  // }),

  createTypeWatcher: type => ({
    type: actions.CREATE_TYPE_WATCHER,
    payload: type,
  }),
  createType: typesById => ({
    type: actions.CREATE_TYPE,
    payload: typesById,
  }),

  deleteTypeWatcher: typeID => ({
    type: actions.DELETE_TYPE_WATCHER,
    payload: typeID,
  }),
  deleteType: typesById => ({
    type: actions.CREATE_TYPE,
    payload: typesById,
  }),

  updateTypeWatcher: type => ({
    type: actions.UPDATE_TYPE_WATCHER,
    payload: type,
  }),
  updateType: typesById => ({
    type: actions.CREATE_TYPE,
    payload: typesById,
  }),





  //PARAMETERS
  LOAD_PARAMETERS_DATA_SAGA: 'LOAD_PARAMETERS_DATA_SAGA',
  SET_PARAMETERS_DATA: 'SET_PARAMETERS_DATA',
  LOAD_PARAMETER_DATA_SAGA: 'LOAD_PARAMETER_DATA_SAGA',
  SET_PARAMETER_DATA: 'SET_PARAMETER_DATA',

  CREATE_PARAMETER_WATCHER: 'CREATE_PARAMETER_WATCHER',
  CREATE_PARAMETER: 'CREATE_PARAMETER',

  DELETE_PARAMETER_WATCHER: 'DELETE_PARAMETER_WATCHER',
  DELETE_PARAMETER: 'DELETE_PARAMETER_WATCHER',

  UPDATE_PARAMETER_WATCHER: 'UPDATE_PARAMETER_WATCHER',
  UPDATE_PARAMETER: 'UPDATE_PARAMETER',

  //SET_PARAMETER_SEARCH_TEXT: 'SET_PARAMETER_SEARCH_TEXT',

  // Parameters Actions
  parametersRenderWatcher: () => ({ type: actions.LOAD_PARAMETERS_DATA_SAGA }),
  setParametersData: parametersById =>({
    type: actions.SET_PARAMETERS_DATA,
    payload: parametersById,
  }),

  parameterRenderWatcher: parameterId => ({
    type: actions.LOAD_PARAMETER_DATA_SAGA,
    payload: parameterId,
  }),
  setParameterData: parameterData => ({
    type: actions.SET_PARAMETER_DATA,
    payload: parameterData,
  }),

  // setParameterSearchText: payload => ({
  //   type: actions.SET_PARAMETER_SEARCH_TEXT,
  //   payload,
  // }),

  createParameterWatcher: parameter => ({
    type: actions.CREATE_PARAMETER_WATCHER,
    payload: parameter,
  }),
  createParameter: parametersById => ({
    type: actions.CREATE_PARAMETER,
    payload: parametersById,
  }),

  deleteParameterWatcher: parameterID => ({
    type: actions.DELETE_PARAMETER_WATCHER,
    payload: parameterID,
  }),
  deleteParameter: parametersById => ({
    type: actions.CREATE_PARAMETER,
    payload: parametersById,
  }),

  updateParameterWatcher: parameter => ({
    type: actions.UPDATE_PARAMETER_WATCHER,
    payload: parameter,
  }),
  updateParameter: parametersById => ({
    type: actions.CREATE_PARAMETER,
    payload: parametersById,
  }),

  
  

};

export default actions;
