import language from '@axicom/config/language.config';

//import englishLang from '@axicom/assets/images/flag/uk.svg';
//import russianLang from '@axicom/assets/images/flag/uk.svg';

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: 'english',
      locale: 'en',
      text: 'English',
      //icon: englishLang,
    },
    {
      languageId: 'russian',
      locale: 'ru',
      text: 'Russian',
      //icon: russianLang,
    },
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
